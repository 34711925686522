<template>
  <div>
    <!--操作栏-->
    <div class="action-bar">
      <el-input
        v-model="params.title"
        placeholder="请输入标题"
        clearable
        size="small"
        class="param-input"
      ></el-input>
      <el-input
        v-model="params.terminal_cdo"
        placeholder="请输入上传者"
        clearable
        size="small"
        class="param-input"
      ></el-input>
      <el-select
        placeholder="请选择媒体类型"
        clearable
        size="small"
        v-model="params.media_type"
        @change="getList('status')"
      >
        <el-option
          :label="item.label"
          :value="item.value"
          v-for="item in media_types"
          :key="item.value"
        ></el-option>
      </el-select>
      <el-button
        type="success"
        size="small"
        icon="el-icon-search"
        @click="getList('status')"
        style="margin-right: 10px"
        >搜索</el-button
      >
      <el-button
        size="small"
        type="warning"
        @click="showDialog"
        style="margin-right: 10px"
        >批量审核</el-button
      >
      <el-button
        size="small"
        type="danger"
        @click="deleteTable"
        style="margin-right: 10px"
        >一键删除</el-button
      >
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      v-loading="loading"
      border
      height="600"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="episode_number"
        label="第几集"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="title"
        label="标题"
        align="center"
        width="200"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        width="100"
        prop="media_type"
        label="媒体类型"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.media_type == 1">动画</span>
          <span v-else-if="scope.row.media_type == 2">漫画</span>
          <span v-else-if="scope.row.media_type == 3">小说</span>
          <span v-else-if="scope.row.media_type == 5">短剧</span>
          <span v-else-if="scope.row.media_type == 6">有声漫</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="media_type"
        label="资源"
        width="160"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="
              scope.row.media_type == 1 ||
              scope.row.media_type == 5 ||
              scope.row.media_type == 6
            "
            @click="lookVideoDialogFun(scope.row)"
            size="mini"
            >播放动漫</el-button
          >
          <el-image
            v-if="scope.row.urls && scope.row.media_type == 2"
            style="width: 100px; height: 100px"
            :src="handleImageUrl(scope.row.urls.split(',')[0])"
            :preview-src-list="
              scope.row.urls.split(',').map((v) => handleImageUrl(v))
            "
          >
          </el-image>
          <div v-if="scope.row.media_type == 3">
            <el-button @click="setText(scope.row.text)" size="mini"
              >查看内容</el-button
            >
            <br />
            <audio controls>
              <source
                :src="handleImageUrl(scope.row.audio_url)"
                type="audio/mpeg"
              />
            </audio>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="fs_resource_status"
        label="文件状态"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{
            fileTypeList.find((v) => v.value == scope.row.fs_resource_status)
              .label
          }}
        </template></el-table-column
      >
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-tag style="margin: 5px 0" type="success">
            <span v-if="scope.row.status == 1">审核通过</span>
            <span v-else-if="scope.row.status == 2">拒绝</span>
            <span v-else-if="scope.row.status == 3">下架</span>
            <span v-else>审核中</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="sale_coin"
        label="价格"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cover"
        label="封面"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image
            :key="scope.row.cover"
            :preview-src-list="[handleImageUrl(scope.row.cover)]"
            :src="handleImageUrl(scope.row.cover)"
          ></el-image> </template
      ></el-table-column>
      <el-table-column
        prop="play_time"
        label="影片时长(s)"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column prop="urls" label="地址集" align="center" width="120">
        <template slot-scope="scope">
          <!-- {{ scope.row.urls.split(",")[0] }} -->
          <el-image
            v-if="scope.row.urls"
            style="width: 70px; height: 70px"
            :src="handleImageUrl(scope.row.urls.split(',')[0])"
            :preview-src-list="
              scope.row.urls.split(',').map((v) => handleImageUrl(v))
            "
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="media_size"
        width="120"
        label="资源大小(M)"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.media_size == 0"> 0 </span>
          <span v-else>
            {{ Number(scope.row.media_size / 1024 / 1024).toFixed(2) }} M
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="width" label="宽" align="center"></el-table-column>
      <el-table-column
        prop="height"
        label="高"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ratio"
        label="宽高比"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reason"
        label="拒绝理由"
        align="center"
        width="200"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.reason || "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="edit_by"
        label="编辑者"
        align="center"
        width="140"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="terminal_cdo"
        label="上传者"
        align="center"
        width="140"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="is_delete" label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="lookVideo(scope.row)"
            v-if="scope.row.fs_resource_status !== 'Completed'"
          >
            刷新状态
          </el-button>
        </template>
      </el-table-column>
      <!-- 
                <el-table-column prop="audio_url" label="有声小说地址" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="review_by" label="编辑相关" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="text" label="内容" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="video_id" label="媒体资源ID" align="center" :show-overflow-tooltip="true"></el-table-column>
              
       -->
    </el-table>
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="params.page"
          :page-size="params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <lookVideoDialog ref="lookDialog"></lookVideoDialog>
    <el-dialog title="查看小说" :visible.sync="dialogVisibles" width="300">
      <p
        style="max-height: 500px; overflow: auto; white-space: pre-line"
        v-html="text"
      ></p>
    </el-dialog>
    <el-dialog
      title="修改ACG状态"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择状态">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in statusList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="拒绝原因"
          v-show="form.status === 2"
          style="margin-bottom: 20px"
        >
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPushData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import lookVideoDialog from "../medium/components/lookVideoDialog";
import API from "@/api";
export default {
  name: "list",
  components: { lookVideoDialog },
  data() {
    return {
      // 表格数据加载动画
      loading: true,
      total: 0,
      tableData: [],
      dialogVisibles: false,
      text: "",
      multipleSelection: [],
      dialogVisible: false,
      form: {},
      fileTypeList: [
        {
          value: "Waiting",
          label: "待检查",
        },
        {
          value: "Uploading",
          label: "文件上传中",
        },
        {
          value: "UploadError",
          label: "文件上传失败",
        },
        {
          value: "UploadCompleted",
          label: "文件上传完成",
        },
        {
          value: "FileMerging",
          label: "文件合并中",
        },
        {
          value: "FileConverting",
          label: "文件转换中",
        },
        {
          value: "FileMergeError",
          label: "文件合并失败",
        },
        {
          value: "FileConvertError",
          label: "文件转换失败",
        },
        {
          value: "CompletedFail",
          label: "处理失败",
        },
        {
          value: "CompressCompleted",
          label: "压缩完成",
        },
        {
          value: "Completed",
          label: "处理完成",
        },
        {
          value: "CheckFail",
          label: "检查状态失败",
        },
      ],
      params: {
        page: 1,
        size: 10,
        status: 0,
        title: undefined, // 标题
        terminal_cdo: undefined, // 上传者
      },
      statusList: [
        {
          label: "审核通过",
          value: 1,
        },
        {
          label: "拒绝",
          value: 2,
        },
        {
          label: "下架",
          value: 3,
        },
      ],
      media_types: [
        {
          label: "动漫",
          value: 1,
        },
        {
          label: "漫画",
          value: 2,
        },
        {
          label: "小说",
          value: 3,
        },
        {
          label: "短剧",
          value: 4,
        },
        {
          label: "有声漫",
          value: 5,
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    setText(text) {
      this.text = text;
      console.log(text);
      this.dialogVisibles = true;
    },
    lookVideoDialogFun(data) {
      console.log(data);
      this.$refs.lookDialog.openlookDialog = true;
      this.$refs.lookDialog.loadVideo(data.m3u8_url);
    },
    handleImageUrl(val) {
      if (val.indexOf("http") === 0) {
        val = "/" + val.split("/").slice(3).join("/");
      } else if (val.indexOf("/") !== 0) {
        val = "/" + val;
      }
      // 根据去掉域名后的数据进行匹配
      let strValue = "";
      let data = JSON.parse(JSON.parse(localStorage.getItem("imageUrl")));
      for (const key in data) {
        if (key.indexOf("/") !== 0 && key !== "*") {
          //如果匹配键名前面不带'/'
          if (val.indexOf("/" + key + "/") === 0) {
            //
            strValue = data[key];
          }
        } else if (key.indexOf("/") === 0 && key !== "*") {
          //带'/'
          if (val.indexOf(key + "/") === 0) {
            //有匹配的
            strValue = data[key];
          }
        }
      }
      if (strValue) {
        return strValue + val;
      } else {
        return data["*"] + val;
      }
    },
    lookVideo(data) {
      API.reloadDetailData({ id: data.id }).then((res) => {
        this.$message.success("刷新成功");
        this.getList();
        console.log(res);
      });
    },
    submitPushData() {
      if (this.form.status) {
        if (this.form.status === 2 && !this.form.reason) {
          this.$message.error("请填写拒绝理由");
          return false;
        }
        let data = {
          ids: this.multipleSelection.map((v) => v.id),
        };
        API.acgDetailUpdateList({ ...data, ...this.form }).then((res) => {
          this.$message.success("批量更新成功");
          this.getList();
          this.dialogVisible = false;
          console.log(res);
        });
      }
    },
    // 分页条数改变回调
    changeSize(size) {
      this.params.size = size;
      this.getList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.params.page = number;
      this.getList();
    },
    getList(type) {
      this.loading = true;
      if (type === "status") {
        this.params.page = 1;
      }
      API.getAcgDetailData(this.params)
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data.data || [];
            this.total = res.data.pagination.count;
          } else {
            this.tableData = [];
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteTable() {
      if (!this.multipleSelection.length) {
        this.$message.error("请先选择数据");
      } else {
        API.acgDetailDeleteList({
          ids: this.multipleSelection.map((v) => v.id),
        }).then((res) => {
          this.$message.success("删除成功");
          this.getList();
          console.log(res);
        });
      }
    },
    showDialog() {
      if (!this.multipleSelection.length) {
        this.$message.error("请先选择数据");
      } else {
        this.dialogVisible = true;
        this.bool = 1;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style scoped></style>
